import request from '@/utils/request';
import axios from 'axios';
import {BASE_URL} from '@/utils/constants.js';
import {
  getOdataFilterStringFromObject,
  getPageOdataString,
  attachOdataQueryString,
} from '@/utils/odata.js';

// 科室列表
export function getVUserAlignmentDepartments(data) {
  let filter = '';
  if (data.physician_name) {
    filter = ` and contains(department_name,'${data.physician_name}')`;
  }
  if (data.institution_id) {
    // 通过医院搜索医生
    filter = filter + ` and institution_id eq '${data.institution_id}'`;
  }
  // 非销售
  let aUrl = !data.isSales
    ? `/api/standard/OData/VUserAlignmentDepartmentManagements`
    : `/api/standard/OData/VUserAlignmentDepartments`;
  return request({
    url: `${aUrl}?$count=true&$skip=${
      (data.pageNum - 1) * data.pageSize
    }&$top=${data.pageSize}
    &$filter=(user_id eq '${data.user_id}')${filter}
    &$orderby=department_name ASC`,
    method: 'get',
  });
}
// 科室列表
export function getVUserAlignmentDepartmentKols(data) {
  let userFilter = `(user_id eq '${data.user_id}')`;
  let filter = '';
  if (data.physician_name) {
    filter = ` and contains(department_name,'${data.physician_name}')`;
  }
  if (data.institution_id) {
    // 通过医院搜索医生
    filter = filter + ` and institution_id eq '${data.institution_id}'`;
  }
  // 非销售
  let aUrl = !data.isSales
    ? `/api/standard/OData/VUserAlignmentDepartmentManagementKols`
    : `/api/standard/OData/VUserAlignmentDepartments`;
  if (!data.isSales) {
    // userFilter = 'true';
  }
  return request({
    url: `${aUrl}?$count=true&$skip=${
      (data.pageNum - 1) * data.pageSize
    }&$top=${data.pageSize}
    &$filter=${userFilter}${filter}
    &$orderby=department_name ASC`,
    method: 'get',
  });
}
// 医院列表
export function getVUserAlignmentInstitutions(data) {
  let filter = '';
  let aUrl = '';
  if (data.physician_name) {
    filter = ` and contains(institution_name,'${data.physician_name}')`;
  }
  if (data.product_id) {
    aUrl = `/api/standard/OData/VUserAlignmentInstitutionProducts`;
    filter = filter + ` and product_id eq '${data.product_id}'`;
  } else {
    aUrl = `/api/standard/OData/VUserAlignmentInstitutions`; // 销售用-不带产品
  }
  // 非销售
  if (!data.isSales) {
    aUrl = `/api/standard/OData/VUserAlignmentInstitutionManagements`;
  }
  return request({
    url: `${aUrl}?$count=true&$skip=${
      (data.pageNum - 1) * data.pageSize
    }&$top=${data.pageSize}
    &$filter=(user_id eq '${data.user_id}')${filter}
    &$orderby=institution_name ASC`,
    method: 'get',
  });
}
// KOL医院列表
export function getVUserAlignmentInstitutionKols(data) {
  let userFilter = `(user_id eq '${data.user_id}')`;
  let filter = '';
  let aUrl = '';
  if (data.physician_name) {
    filter = ` and contains(institution_name,'${data.physician_name}')`;
  }
  if (data.product_id) {
    aUrl = `/api/standard/OData/VUserAlignmentInstitutionProducts`;
    filter = filter + ` and product_id eq '${data.product_id}'`;
  } else {
    aUrl = `/api/standard/OData/VUserAlignmentInstitutions`; // 销售用-不带产品
  }
  // 非销售
  if (!data.isSales) {
    aUrl = `/api/standard/OData/VUserAlignmentInstitutionManagementKols`;
    // userFilter = 'true';
  }
  return request({
    url: `${aUrl}?$count=true&$skip=${
      (data.pageNum - 1) * data.pageSize
    }&$top=${data.pageSize}
    &$filter=${userFilter}${filter}
    &$orderby=institution_name ASC`,
    method: 'get',
  });
}

// 医生列表搜索 条件处理
export function commonPhysicianListFilterFn(data, sortOrder) {
  let filter = '';
  let sortString = '';
  if (data.physician_name) {
    filter = ` and (contains(physician_name,'${data.physician_name}') Or contains(department_name,'${data.physician_name}') Or contains(institution_name,'${data.physician_name}') or contains(physician_id, '${data.physician_name}'))`;
  }
  if (data.institution_id) {
    // 通过医院搜索医生
    filter = filter + ` and institution_id eq '${data.institution_id}'`;
  }
  if (data.department_name) {
    filter = filter + ` and department_name eq '${data.department_name}'`;
  }
  if (data.is_neu || data.is_psy) {
    if (data.is_neu && data.is_psy) {
      filter += ' and (is_neu eq 1 or is_psy eq 1)';
    } else if (data.is_neu) {
      filter += ' and (is_neu eq 1)';
    } else if (data.is_psy) {
      filter += ' and (is_psy eq 1)';
    }
  }
  if (sortOrder === 'physician_segment_sort') {
    sortString = 'physician_segment_sort ASC';
  } else if (sortOrder === 'institution_name') {
    sortString = 'institution_name ASC,physician_name ASC';
  } else if (sortOrder === 'physician_name') {
    sortString = 'is_favorite desc, physician_name ASC,institution_name ASC';
  } else { // (sortOrder === 'physician_name_no_favorite') {
    sortString = 'physician_name ASC,institution_name ASC';
  }
  return {
    filter: filter,
    sortString: sortString,
  };
}
// 所有 医生列表
export function getAllPhysicianList(data, sortOrder) {
  let cObj = commonPhysicianListFilterFn(data, sortOrder);
  let filter = cObj.filter;
  let sortString = cObj.sortString;
  if (data.clickSearch) {
    // 查询 外部数据医生数
    // 微信医生绑定数
    return Promise.all([
      request({
        url: `/api/standard/OData/Physicians/$count?$filter=(ex_data_support eq 1)${filter}`,
        method: 'get',
      }),
      request({
        url: `/api/standard/OData/Physicians/$count?$filter=(is_wechat eq 1)${filter}`,
        method: 'get',
      }),
      request({
        url: `/api/standard/OData/Physicians?$count=true&$skip=${
          (data.pageNum - 1) * data.pageSize
        }&$top=${data.pageSize}
        &$filter=${filter.replace(' and ', '')}
        &$orderby=${sortString}`,
        method: 'get',
      }),
    ]);
  } else {
    return request({
      url: `/api/standard/OData/Physicians?$count=true&$skip=${
        (data.pageNum - 1) * data.pageSize
      }&$top=${data.pageSize}
      &$filter=${filter.replace(' and ', '')}
      &$orderby=${sortString}`,
      method: 'get',
    });
  }
}
// 销售 医生列表
export function getPhysicianList(data, sortOrder) {
  let cObj = commonPhysicianListFilterFn(data, sortOrder);
  let filter = cObj.filter;
  let sortString = cObj.sortString;
  if (data.clickSearch) {
    // 查询 外部数据医生数
    // 微信医生绑定数
    return Promise.all([
      request({
        url: `/api/standard/OData/VUserAlignments/$count?$filter=(user_id eq '${data.user_id}' and ex_data_support eq 1)${filter}`,
        method: 'get',
      }),
      request({
        url: `/api/standard/OData/VUserAlignments/$count?$filter=(user_id eq '${data.user_id}' and is_wechat eq 1)${filter}`,
        method: 'get',
      }),
      request({
        url: `/api/standard/OData/VUserAlignments?$count=true&$skip=${
          (data.pageNum - 1) * data.pageSize
        }&$top=${data.pageSize}
        &$filter=(user_id eq '${data.user_id}')${filter}
        &$orderby=${sortString}`,
        method: 'get',
      }),
    ]);
  } else {
    return request({
      url: `/api/standard/OData/VUserAlignments?$count=true&$skip=${
        (data.pageNum - 1) * data.pageSize
      }&$top=${data.pageSize}
      &$filter=(user_id eq '${data.user_id}')${filter}
      &$orderby=${sortString}`,
      method: 'get',
    });
  }
}
// 管理 医生列表
export function getManagementPhysicianList(data, sortOrder) {
  let cObj = commonPhysicianListFilterFn(data, sortOrder);
  let filter = cObj.filter;
  let sortString = cObj.sortString;
  if (data.clickSearch) {
    return Promise.all([
      request({
        url: `/api/standard/OData/VUserAlignmentManagements/$count?$filter=(user_id eq '${data.user_id}' and ex_data_support eq 1)${filter}`,
        method: 'get',
      }),
      request({
        url: `/api/standard/OData/VUserAlignmentManagements/$count?$filter=(user_id eq '${data.user_id}' and is_wechat eq 1)${filter}`,
        method: 'get',
      }),
      request({
        url: `/api/standard/OData/VUserAlignmentManagements?$count=true&$skip=${
          (data.pageNum - 1) * data.pageSize
        }&$top=${data.pageSize}
        &$filter=(user_id eq '${data.user_id}')${filter}
        &$orderby=${sortString}`,
        method: 'get',
      }),
    ]);
  } else {
    return request({
      url: `/api/standard/OData/VUserAlignmentManagements?$count=true&$skip=${
        (data.pageNum - 1) * data.pageSize
      }&$top=${data.pageSize}
      &$filter=(user_id eq '${data.user_id}')${filter}
      &$orderby=${sortString}`,
      method: 'get',
    });
  }
}
// 医学管理 KOL列表
export function getManagementKOLList(data, sortOrder) {
  let cObj = commonPhysicianListFilterFn(data, sortOrder);
  let filter = cObj.filter;
  let sortString = cObj.sortString;
  if (data.clickSearch) {
    return Promise.all([
      request({
        url: `/api/standard/OData/VUserAlignmentManagementKols/$count?$filter=(user_id eq '${data.user_id}' and ex_data_support eq 1)${filter}`,
        method: 'get',
      }),
      request({
        url: `/api/standard/OData/VUserAlignmentManagementKols/$count?$filter=(user_id eq '${data.user_id}' and is_wechat eq 1)${filter}`,
        method: 'get',
      }),
      request({
        url: `/api/standard/OData/VUserAlignmentManagementKols?$count=true&$skip=${
          (data.pageNum - 1) * data.pageSize
        }&$top=${data.pageSize}
        &$filter=(user_id eq '${data.user_id}')${filter}
        &$orderby=${sortString}`,
        method: 'get',
      }),
    ]);
  } else {
    return request({
      url: `/api/standard/OData/VUserAlignmentManagementKols?$count=true&$skip=${
        (data.pageNum - 1) * data.pageSize
      }&$top=${data.pageSize}
      &$filter=(user_id eq '${data.user_id}')${filter}
      &$orderby=${sortString}`,
      method: 'get',
    });
  }
}
// 销售 产品分型医生列表
export function getProductPhysicianList(data, sortOrder) {
  let cObj = commonPhysicianListFilterFn(data, sortOrder);
  let filter = cObj.filter;
  let sortString = cObj.sortString;

  if (data.product_id) {
    if (data.product_id.indexOf('only_') === 0) {
      filter += ` and product_id eq '${data.product_id.substr(
        5,
      )}' and product_no eq 1`;
    } else {
      filter += ` and product_id eq '${data.product_id}'`;
    }
  }
  if (data.result) {
    if (data.product_id === 'Brintellix_000001') {
      filter += ` and result eq '${data.result}'`;
    } else {
      filter += ` and result_search eq '${data.result}'`;
    }
  }
  if (data.clickSearch) {
    return Promise.all([
      request({
        url: `/api/standard/OData/VUserAlignmentProductTypings/$count?$filter=(user_id eq '${data.user_id}' and ex_data_support eq 1)${filter}`,
        method: 'get',
      }),
      request({
        url: `/api/standard/OData/VUserAlignmentProductTypings/$count?$filter=(user_id eq '${data.user_id}' and is_wechat eq 1)${filter}`,
        method: 'get',
      }),
      request({
        url: `/api/standard/OData/VUserAlignmentProductTypings?$count=true&$skip=${
          (data.pageNum - 1) * data.pageSize
        }&$top=${data.pageSize}
        &$filter=(user_id eq '${data.user_id}')${filter}
        &$orderby=${sortString}`,
        method: 'get',
      }),
    ]);
  } else {
    return request({
      url: `/api/standard/OData/VUserAlignmentProductTypings?$count=true&$skip=${
        (data.pageNum - 1) * data.pageSize
      }&$top=${data.pageSize}
      &$filter=(user_id eq '${data.user_id}')${filter}
      &$orderby=${sortString}`,
      method: 'get',
    });
  }
}
// 管理 产品分型医生列表
export function getManagementProductPhysicianList(data, sortOrder) {
  let cObj = commonPhysicianListFilterFn(data, sortOrder);
  let filter = cObj.filter;
  let sortString = cObj.sortString;

  if (data.product_id) {
    filter += ` and product_id eq '${data.product_id}'`;
  }
  if (data.result) {
    filter += ` and result_search eq '${data.result}'`;
  }
  if (data.clickSearch) {
    return Promise.all([
      request({
        url: `/api/standard/OData/VUserAlignmentProductTypingManagements/$count?$filter=(user_id eq '${data.user_id}' and ex_data_support eq 1)${filter}`,
        method: 'get',
      }),
      request({
        url: `/api/standard/OData/VUserAlignmentProductTypingManagements/$count?$filter=(user_id eq '${data.user_id}' and is_wechat eq 1)${filter}`,
        method: 'get',
      }),
      request({
        url: `/api/standard/OData/VUserAlignmentProductTypingManagements?$count=true&$skip=${
          (data.pageNum - 1) * data.pageSize
        }&$top=${data.pageSize}
        &$filter=(user_id eq '${data.user_id}')${filter}
        &$orderby=${sortString}`,
        method: 'get',
      }),
    ]);
  } else {
    return request({
      url: `/api/standard/OData/VUserAlignmentProductTypingManagements?$count=true&$skip=${
        (data.pageNum - 1) * data.pageSize
      }&$top=${data.pageSize}
      &$filter=(user_id eq '${data.user_id}')${filter}
      &$orderby=${sortString}`,
      method: 'get',
    });
  }
}
// 查询列表
export function getPhysiciansList() {
  return request({
    url: `/api/standard/OData/Physicians?$count=true`, // &$skip=0&$top=500
    // url: `/api/standard/odata/physicians?$top=10&$count=true&$filter=(physician_id%20eq%20%270012v00002maoQaAAI%27%20or%20physician_id%20eq%20%270012v00002marW2AAI%27%20or%20physician_id%20eq%20%270012v00002masf9AAA%27%20or%20physician_id%20eq%20%270012v00002mauH2AAI%27)`,
    method: 'get',
  });
}
// 查询关注医生详情
export function getStarPhysiciansDetail(physician_id, user_id) {
  return request({
    url: `/api/standard/OData/VUserAlignments?$filter=physician_id eq '${physician_id}' and user_id eq '${user_id}'
    &$expand=physician`,
    method: 'get',
  });
}
// 查询详情
export function getPhysiciansDetail(physician_id) {
  return request({
    url: `/api/standard/OData/Physicians?$filter=physician_id eq '${physician_id}'
    &$expand=physician__physician_scores`,
    method: 'get',
  });
}
// 查询医生信息
export function getPhysicians(physician_id) {
  return request({
    url: `/api/standard/OData/Physicians?$filter=physician_id eq '${physician_id}'&$expand=physician__physician_educations,physician__physician_awards,physician__physician_publications($select=view_key)`,
    method: 'get',
  });
}
// 查询医生NMPA
export function getPhysicianNMPA(physician_id) {
  return request({
    url: `/api/standard/OData/PhysicianNmpas?$filter=physician_id eq '${physician_id}' and contact_type eq '医药'`,
    method: 'get',
  });
}
// 查询医院信息
export function getTargetSaleses(institution_id, territory_id) {
  return request({
    url: `/api/standard/OData/targetSaleses?$filter=institution_id eq '${institution_id}' and territory_id eq '${territory_id}' &$orderby=period desc`,
    method: 'get',
  });
}
// 查询进院数据
export function getLstListings(institution_id) {
  return request({
    url: `/api/standard/OData/lstListings?$filter=institution_id eq '${institution_id}'`,
    method: 'get',
  });
}

// 查询医生所属产品
export function getProductPhysicians(physician_id) {
  return request({
    url: `/api/standard/OData/ProductPhysicians?$filter=physician_id eq '${physician_id}'`,
    method: 'get',
  });
}
// 查询用户所属产品
export function getUserProduct(physician_id) {
  return request({
    url: `/api/standard/OData/vUserProducts?$filter=user_id eq '${physician_id}'`,
    method: 'get',
  });
}
// 沟通风格标签
export function getCommStylesLabel(data) {
  return request({
    url: `/api/standard/OData/VPhysicianCommunicationStyleNewests?$filter=physician_id eq '${data.physician_id}' and user_id eq '${data.user_id}'&$select=communication_style`,
    method: 'get',
  });
}
// 查询评估score
export function getPhysiciansScore(physician_id) {
  // &$expand=physician__physician_engage_content_stats  &$expand=physician__physician_scores
  return request({
    url: `/api/standard/OData/Physicians?$filter=physician_id eq '${physician_id}'
    &$select=specialty,brief,institution_name,department_name,update_date
    &$expand=physician__physician_educations($orderby=graduate_date asc)
    &$expand=physician__physician_schedules($orderby=sequence asc)
    `,
    method: 'get',
  });
}
// 查询企业合作
export function getPhysicianScoresByYear(physician_id, year) {
  return request({
    url: `/api/standard/OData/PhysicianScores?$filter=physician_id eq '${physician_id}'`,
    method: 'get',
  });
}
/**
 * 学术成果 - 论文发表：PhysicianPublications
  学术成果 - 临床试验：PhysicianClinicalTrials
  学术成果 - 国自然基金：PhysicianProjects
  学术成果 - 获奖：PhysicianAwards
  任职状态 - 协会：PhysicianAssociations
  任职状态 - 期刊：PhysicianJournals
  网络动态 - PhysicianWechats
  接触动态 - 会议 physician_meeting
  接触动态 - 会议 physician__physician_calls
 * @param {*} physician_id
 * @returns
 */
export function gitAcademicList(physician_id) {
  return request({
    url: `/api/standard/OData/Physicians?$filter=physician_id eq '${physician_id}'
    &$expand=physician__physician_publications($orderby=publish_date desc;$select=view_key,publication_title,keywords,publish_date,journal),
    physician__physician_clinical_trials($orderby=registration_date desc),
    physician__physician_projects($orderby=exec_date_end desc),
    physician__physician_awards($orderby=award_time desc),
    physician__physician_associations,
    physician__physician_journals,
    physician__physician_wechats,
    physician__physician_calls($orderby=call_time desc),
    physician__physician_meetings($orderby=meeting_start_date desc)`,
    method: 'get',
  });
}
// 查询论文关键词趋势数据
export function getPhysicianPublicationKeywordStats(data) {
  // (keyword eq '肺' or keyword eq '肾' or keyword eq 'CAG' or keyword eq '白血病')  and keyword eq '${data.keyword}'
  return request({
    url: `/api/standard/odata/PhysicianPublicationKeywordStats?$count=true&$skip=0&$top=5
    &$filter=(year eq '${data.year}')`,
    method: 'get',
  });
}
// 获取个人论文关键字趋势数据
export function getPhysicianPublicationKeywordStatsByPhysicianId(physician_id) {
  return request({
    url: `/api/standard/odata/PhysicianPublicationKeywordStats?$count=true&$filter=(physician_id eq '${physician_id}')
    &$select=view_key,keyword,keyword_no,year`,
    method: 'get',
  });
}

// 查询学术成果 - 论文发表
export function getPublicationsByPhysicianId(physician_id) {
  return request({
    url: `/api/standard/OData/PhysicianPublications?$filter=physician_id eq '${physician_id}'
    &$orderby=publish_date desc&$select=view_key,publication_title,publication_id,keywords,publish_date,journal,journal_if,authors,author_seq_no,labels,first_author`,
    method: 'get',
  });
}
// 查询学术成果 - 论文发表
export function getPublicationsListByPhysicianId(physician_id) {
  return request({
    url: `/api/standard/OData/Physicians?$filter=physician_id eq '${physician_id}'
    &$expand=physician__physician_publications($orderby=publish_date desc;$select=view_key,publication_title,publication_id,keywords,publish_date,journal,journal_if,authors,author_seq_no,labels,first_author)
    &$expand=physician__physician_publication_keywords($select=publication_id,keyword,year)`,
    method: 'get',
  });
}
// export function getPublicationsListByPhysicianId(physician_id) {
//   return request({
//     url: `/api/standard/OData/PhysicianPublications?$filter=physician_id eq '${physician_id}' &$orderby=publish_date desc`,
//     method: 'get'
//   });
// }
// 指南共识
export function getGuidesByPhysicianId(physician_id) {
  return request({
    url: `/api/standard/OData/PhysicianGuides?$filter=physician_id eq '${physician_id}' &$orderBy=release_time desc`,
    method: 'get',
  });
}
// 专利发明
export function getPatentsByPhysicianId(physician_id) {
  return request({
    url: `/api/standard/OData/PhysicianPatents?$filter=physician_id eq '${physician_id}' &$orderby=publish_date desc`,
    method: 'get',
  });
}

// author_seq_no: 0
// authors: "JX Zhang，J Li，C Chen，T Yin，YH Zhang"
// doi: "10.1016/j.archger.2021.104389"
// is_mm: 0
// journal: "Archives of Gerontology and Geriatrics"
// journal_if: 2.13
// journal_is_core: "非核心"
// journal_tier: "国际级"
// keywords: ""
// physician_id: "0012v00002mapMzAAI"
// publication_id: "1r090p00550w0rs0mc460vp07d783509"
// publication_title: "Reference values of skeletal muscle mass, fat mass and fat-to-muscle ratio for rural middle age and older adults in western China"
// publish_date: "2021-01-01T00:00:00Z"
// source_uuid: "ef674df57eee41c897d3f8cc2a89a021"
// view_key:

// 论文发表 详情
export function getPublicationsDetail(view_key) {
  return request({
    url: `/api/standard/OData/PhysicianPublications?$filter=view_key eq '${view_key}'`,
    method: 'get',
  });
}
// 论文发表 详情
export function getKolPublicationsDetail(network_content_id, physician_id) {
  return request({
    url: `/api/standard/OData/PhysicianPublications?$filter=publication_id eq '${network_content_id}' and physician_id eq '${physician_id}'`,
    method: 'get',
  });
}
// 查询学术成果 - 临床试验
export function getPhysicianClinicalTrialsByPhysicianId(physician_id) {
  return request({
    url: `/api/standard/OData/PhysicianClinicalTrials?$filter=physician_id eq '${physician_id}'
    &$orderby=registration_date desc
    &$select=view_key,professional_subject,applier_name,registration_date,period,status,is_pi`,
    method: 'get',
  });
}
// 临床试验 详情
export function getPhysicianClinicalTrialsDetail(view_key) {
  return request({
    url: `/api/standard/OData/PhysicianClinicalTrials?$filter=view_key eq '${view_key}'`,
    method: 'get',
  });
}
// 临床试验 详情
export function getKolPhysicianClinicalTrialsDetail(
  network_content_id,
  physician_id,
) {
  return request({
    url: `/api/standard/OData/PhysicianClinicalTrials?$filter=clinical_trial_id eq '${network_content_id}' and physician_id eq '${physician_id}'`,
    method: 'get',
  });
}
// 查询同科室临床试验
export function getCommonPhysicianClinicalTrialsByPhysicianId(
  physician_id,
  institution_name,
  department_name,
) {
  return request({
    // physician_id ne '${physician_id}' and
    url: `/api/standard/OData/VPhysicianClinicalTrials?$filter=institution_name eq '${institution_name}' and department_name eq '${department_name}'
    &$orderby=registration_date desc
    &$select=view_key,professional_subject,applier_name,registration_date,period,status,applier_contact,physician_name,is_pi`,
    method: 'get',
  });
}
// 查询学术成果 - 国自然基金
export function getPhysicianProjectsByPhysicianId(physician_id) {
  return request({
    url: `/api/standard/OData/PhysicianProjects?$filter=physician_id eq '${physician_id}'
    &$orderby=exec_date_start desc
    &$select=view_key,project_title,project_fund_name,exec_date_start`,
    method: 'get',
  });
}
// 学术成果 - 获奖
export function getPhysicianAwardsByPhysicianId(physician_id) {
  return request({
    url: `/api/standard/OData/PhysicianAwards?$filter=physician_id eq '${physician_id}'
    &$orderby=award_time desc
    &$select=view_key,award_name,award_time`,
    method: 'get',
  });
}
// 学术动态List
export function getVPhysicianAcademicAchievementsByPhysicianId(physician_id) {
  return request({
    url: `/api/standard/OData/VPhysicianAcademicAchievements?$count=true&$filter=physician_id eq '${physician_id}'`,
    method: 'get',
  });
}

/**
 * 任职状态 - 协会：PhysicianAssociations
   任职状态 - 期刊：PhysicianJournals
 * @param {*} physician_id
 * @returns
 */
// 任职状态 - 协会
export function getPhysicianAssociationsByPhysicianId(physician_id) {
  return request({
    url: `/api/standard/OData/PhysicianAssociations?$filter=physician_id eq '${physician_id}'`,
    method: 'get',
  });
}
// 任职状态 - 期刊
export function getPhysicianJournalsByPhysicianId(physician_id) {
  return request({
    url: `/api/standard/OData/PhysicianJournals?$filter=physician_id eq '${physician_id}'`,
    method: 'get',
  });
}
// 网络动态
export function getPhysicianWechatsByPhysicianId(physician_id) {
  return request({
    // url: `/api/standard/OData/PhysicianWechats?$filter=physician_id eq '${physician_id}'`,
    url: `/api/standard/OData/VPhysicianNetworkActions?$filter=physician_id eq '${physician_id}'&$orderby=publish_date desc`,
    method: 'get',
  });
}
// 360画像 - 根据type查询标签
export function getLabelsByPhysicianId(physician_id) {
  return request({
    url: `/api/standard/OData/DsPhysicianLabels?$filter=physician_id eq '${physician_id}'&$orderby=label_order`,
    // url: `/api/standard/OData/DsPhysicianLabels`,
    method: 'get',
  });
}
// 标签视图
export function getVPhysicianLabelByPhysicianId(physician_id) {
  return request({
    url: `/api/standard/OData/DsPhysicianLabels?$filter=physician_id eq '${physician_id}'&$orderby=label_order asc`,
    // url: `/api/standard/OData/DsPhysicianLabels`,
    method: 'get',
  });
}
// 360画像 - 影响力
export function getEffectByPhysicianId(physician_id) {
  return request({
    url: `/api/standard/OData/PhysicianScores?$filter=physician_id eq '${physician_id}'`,
    method: 'get',
  });
}
// 获取网络动态词云数据
export function getPhysicianNetworkActionKeywordStatsByPhysicianId(
  physician_id,
) {
  return request({
    url: `/api/standard/odata/PhysicianNetworkActionKeywordStats?$count=true&$filter=(physician_id eq '${physician_id}')
    `,
    method: 'get',
  });
}
// 获取动态关键词
export function getVPhysicianActivityFactLabelStats(physician_id) {
  return request({
    url: `/api/standard/odata/PhysicianActivityLabelStats?$count=true&$filter=(physician_id eq '${physician_id}')`,
    method: 'get',
  });
}

// 企业合作
export function getManufactoryStatsByPhysicianId(physician_id) {
  return request({
    url: `/api/standard/OData/PhysicianManufactoryStats?$filter=physician_id eq '${physician_id}'`,
    method: 'get',
  });
}
// 分型历史
export function getPerceptionsHistoryByPhysicianId(physician_id) {
  return request({
    // url: `/api/standard/OData/PhysicianPerceptions?$filter=physician_id eq '${physician_id}'&$orderby=perception_date desc&$top=6`,
    // and physician_type ne null&$orderby=create_date desc&$top=6&$select=physician_type,create_date
    url: `/api/standard/OData/VPhysicianOperationStateDays?$filter=physician_id eq '${physician_id}'&$orderby=date desc`,
    method: 'get',
  });
}
// 产品时间趋势
export function getTimeTrendByPhysicianId(physician_id) {
  let max = new Date().getFullYear() - 1;
  let min = max - 9;
  return request({
    // url: `/api/standard/OData/PhysicianProductVolumeTrendStats?$filter=physician_id eq '${physician_id}' and year ge ${min} and year le ${max}
    url: `/api/standard/OData/PhysicianProductVolumeTrendStats?$filter=physician_id eq '${physician_id}'
    `,
    method: 'get',
  });
}
// 产品声量分析
// export function getProductVolumeByPhysicianId(physician_id) {
//   return request({
//     url: `/api/standard/OData/PhysicianProductVolumeStats?$filter=(physician_id eq '${physician_id}')
//     `,
//     method: 'get'
//   });
// }
//  企业合作趋势
export function getPhysicianManufactoryTrendStatsByPhysicianId(physician_id) {
  let max = new Date().getFullYear() - 1; // 近10年数据
  let min = max - 9;
  return request({
    // url: `/api/standard/OData/PhysicianManufactoryTrendStats?$filter=physician_id eq '${physician_id}' and year ge ${min} and year le ${max}
    url: `/api/standard/OData/PhysicianManufactoryTrendStats?$filter=physician_id eq '${physician_id}'
    `,
    method: 'get',
  });
}
//  所有企业合作  根据年份查询
export function searchPhysicianManufactoryByYear(physician_id, year) {
  return request({
    url: `/api/standard/OData/PhysicianManufactoryStats?$filter=physician_id eq '${physician_id}' and year eq '${year}'
    `,
    method: 'get',
  });
}
// 客户互动趋势图
export function getChannelTrendStatsByPhysicianId(physician_id) {
  return request({
    url: `/api/standard/OData/PhysicianEngageChannelTrendStats?$filter=physician_id eq '${physician_id}'
    &$orderby=month desc`,
    method: 'get',
  });
}
export function getChannelTrendListByPhysicianId(physician_id) {
  return request({
    url: `/api/standard/OData/VPhysicianTrendLists?$filter=physician_id eq '${physician_id}'
    &$orderby=start_date desc`,
    method: 'get',
  });
}
// 同类产品合作 统计
export function searchProductVolumesByYear(physician_id, year) {
  // &$expand=physician__physician_product_volume_stats($filter=year eq '${year}')
  // &$expand=physician__v_physician_product_volumes${yearNo}
  return request({
    url: `/api/standard/OData/PhysicianProductVolumeStats?$filter=physician_id eq '${physician_id}' and year eq '${year}'
    `,
    method: 'get',
  });
}

// 同类产品合作 趋势
export function searchProductVolumesList(physician_id, year) {
  return request({
    url: `/api/standard/OData/PhysicianProductVolumeTrendStats?$filter=physician_id eq '${physician_id}'
    `,
    method: 'get',
  });
}
// 同类产品合作
export function getProductVolumesByPhysicianId(data) {
  let yearNo =
    data.year === 'all'
      ? ` and type eq '${data.type}'`
      : ` and year_no le ${data.year} and type eq ${data.type}`;
  // physician_id eq '${physician_id} and
  return request({
    url: `/api/standard/OData/VPhysicianProductVolumes?$filter=physician_id eq '${data.physician_id}' and product_name eq '${data.product_name}'${yearNo}
    &$orderby=date desc`,
    method: 'get',
  });
}
// 获取进药计划
export function getInstitutionListings(institutionId) {
  return request({
    url: `/api/standard/OData/InstitutionDevices?$filter=institution_id eq '${institutionId}'`,
    method: 'get',
  });
}
// 获取渗透率
export function getPhysicianSurveyResults(physician_id) {
  return request({
    url: `/api/standard/OData/PhysicianSurveyResults?$filter=physician_id eq '${physician_id}'`,
    method: 'get',
  });
}
// 获取排序数据
export function getSortDynamicsByUserId(user_id) {
  return request({
    url: `/api/standard/OData/SortDynamics?$filter=user_id eq '${user_id}'`,
    method: 'get',
  });
}
export function updateSortDynamicsByUserId(data) {
  return request({
    url: `/api/standard/SortDynamics`,
    method: 'put',
    data: data,
  });
}
export function addSortDynamics(data, method) {
  return request({
    url: `/api/standard/SortDynamics`,
    method: method,
    data: data,
  });
}
// 圈层关系
export function getQuanList(physician_id) {
  return request({
    url: `/api/standard/OData/PhysicianNetworkContents?$filter=network_type eq '同圈层' and physician_id eq '${physician_id}'&$expand=physician($select=physician_name,institution_name)`,
    method: 'get',
  });
}
export function getQuanSelect(physician_id) {
  return request({
    url: `/api/standard/OData/PhysicianNetworkContents?$filter=network_type eq '同圈层'&$select=network_content_text`,
    method: 'get',
  });
}
// 圈层关系 完整圈层名单
export function getQuanDetailList(circle_name) {
  return request({
    url: `/api/standard/OData/VPhysicianUnionAllCircles?$filter=circle_name eq '${circle_name}'`,
    method: 'get',
  });
}
// 新增圈层
export function addQuan(data) {
  return request({
    url: `/api/standard/OData/PhysicianCircles`,
    method: 'post',
    data: data,
  });
}
// 删除圈层
export function delQuan(data) {
  return request({
    url: `/api/standard/PhysicianCircles`,
    method: 'put',
    data: data,
  });
}
export function addQuanAfter(physician_id) {
  return request({
    url: `/api/standard/OData/VPhysicianCircleNewests?$filter=physician_id eq '${physician_id}'`,
    method: 'get',
  });
}
// 手术技能评估
export function getSkillData(physician_id) {
  return request({
    url: `/api/standard/OData/VPhysicianOperationSkillNewests?$filter=physician_id eq '${physician_id}'`,
    method: 'get',
  });
}
// 手术技能评估  修改
export function editSkillData(data) {
  return request({
    url: `/api/standard/PhysicianOperationSkills`,
    method: 'post',
    data: data,
  });
}
// 新增 不使用B司IVUS的原因
export function addIVUS(data) {
  return request({
    url: `/api/standard/OData/PhysicianNoivusReasons`,
    method: 'post',
    data: data,
  });
}
// 不使用B司IVUS的原因
export function getIVUS(physician_id) {
  return request({
    url: `/api/standard/OData/VPhysicianNoivusReasonNewests?$filter=physician_id eq '${physician_id}'`,
    method: 'get',
  });
}
// 手术情形
export function getOperationStateNewests(physician_id) {
  return request({
    url: `/api/standard/OData/VPhysicianOperationStateNewests?$filter=physician_id eq '${physician_id}'`,
    method: 'get',
  });
}
// 获取渗透率
export function getOperationStl(physician_id) {
  return request({
    url: `/api/standard/OData/VPhysicianOperationStateNewests?$filter=physician_id eq '${physician_id}'&$select=bsc_avg,volcano_avg,oct_avg`,
    method: 'get',
  });
}
// 新增 手术情形
export function addOperationState(data) {
  return request({
    url: `/api/standard/OData/PhysicianOperationStates`,
    method: 'post',
    data: data,
  });
}
// 线上活跃度 邀请统计
export function getPhysicianVisionInviteStats(data) {
  return request({
    url: `/api/standard/OData/PhysicianVisionInviteStats?$filter=physician_id eq '${data.physician_id}' and last_month eq ${data.last_month}`,
    method: 'get',
  });
}
// 线上活跃度 邀请列表
export function getPhysicianVisionInvites(data) {
  let filter =
    data.last_month !== -1
      ? `?$filter=physician_id eq '${data.physician_id}' and last_month eq ${data.last_month}`
      : `?$filter=physician_id eq '${data.physician_id}'`;
  return request({
    url: `/api/standard/OData/PhysicianVisionInvites${filter}`,
    method: 'get',
  });
}
// 视频播放次数
export function getPhysicianVisionTimesStats(data) {
  return request({
    url: `/api/standard/OData/PhysicianVisionTimesStats?$filter=physician_id eq '${data.physician_id}' and type eq '${data.category}' and last_month eq ${data.last_month}`,
    method: 'get',
  });
}
// 线上活跃度中间内容统计
export function getPhysicianVisionContentStats(data) {
  let category = `category eq '${data.category}'`;
  let filter =
    data.last_month !== -1
      ? `?$filter=physician_id eq '${data.physician_id}' and last_month eq ${data.last_month} and ${category}`
      : `?$filter=physician_id eq '${data.physician_id}' and ${category}`;
  return request({
    url: `/api/standard/OData/PhysicianVisionContentStats${filter}`,
    method: 'get',
  });
}
// 获取渗透率
export function getDsFactLabels(physician_id) {
  //
  return request({
    url: `/api/standard/OData/DsFactLabels?$filter=physician_id eq '${physician_id}'&$select=level_5`,
    method: 'get',
  });
}
// 动态详情列表
export function getVPhysicianActivities(physician_id, {activity_type} = {}) {
  let filter = `physician_id eq '${physician_id}'`;
  if (activity_type?.length) {
    filter += ` and (${activity_type
      .map((item) => `activity_type eq '${item}'`)
      .join(' or ')})`;
  }
  let url = `/api/standard/OData/physicianActivities?$filter=${filter}&$orderby=activity_date_sort desc`;

  return request({
    url,
    method: 'get',
  });
}
export function getVPhysicianWecharts(physician_id) {
  //
  return request({
    url: `/api/standard/OData/VPhysicianWecharts?$filter=physician_id eq '${physician_id}'`,
    method: 'get',
  });
}

// 学术成果汇总
export function getPhysicianDataStats(physician_id) {
  return request({
    url: `/api/standard/OData/physicianDataStats?$filter=physician_id eq '${physician_id}'&$top=1`,
    method: 'get',
  });
}
// 获取医生产品分型
export function getPhysicianTyping(data) {
  return request({
    url: `/api/standard/OData/physicianTypings?$filter=physician_id eq '${data.physician_id}' and product_id eq '${data.product_id}' &$orderby=create_time desc`,
    method: 'get',
  });
}
// 获取医院产品分型
export function getInstitutionTyping(data) {
  return request({
    url: `/api/standard/OData/institutionTypings?$filter=institution_id eq '${data.institution_id}' and product_id eq '${data.product_id}'  &$orderby=create_time desc`,
    method: 'get',
  });
}
// 存入医生问题答案
export function setPhysicianTyping(data) {
  return request({
    url: `/api/standard/OData/physicianTypings`,
    method: 'post',
    data: data,
  });
}

// 存入医院问题答案
export function setInstitutionTyping(data) {
  return request({
    url: `/api/standard/OData/institutionTypings`,
    method: 'post',
    data: data,
  });
}
// 设置医生线上诊断平台
export function setOnlineDiagnosis(data) {
  return request({
    url: `/api/standard/OData/physicianOnlineDiagnosises`,
    method: 'post',
    data: data,
  });
}

// 获取医生线上诊断平台信息
export function getOnlineDiagnosis(data) {
  return request({
    url: `/api/standard/OData/physicianOnlineDiagnosises?$filter=physician_id eq '${data.physician_id}' and territory_id eq '${data.territory_id}' &$orderby=create_time desc &$top=1`,
    method: 'get',
  });
}

// 查询用户职位
export function getTerritories(user_id) {
  return request({
    url: `/api/standard/OData/UserRoles?$filter=user_id eq '${user_id}'&$expand=territory`,
    method: 'get',
  });
}
// 外部会议参会情况保存
export function savePhysicianMeetingExJoin(data) {
  return request({
    url: `/api/standard/OData/physicianMeetingExJoins`,
    method: 'post',
    data: data,
  });
}
// 外部会议参会情况修改
export function updatePhysicianMeetingExJoin(data) {
  return request({
    url: `/api/standard/PhysicianMeetingExJoins`,
    method: 'put',
    data: data,
  });
}

// 外部会议参会情况删除
export function deletePhysicianMeetingExJoin(meeting_id) {
  return request({
    url: `/api/standard/PhysicianMeetingExJoins/${meeting_id}`,
    method: 'delete',
  });
}
// 查询外部会议列表
export function getPhysicianMeetingExJoins(physician_id) {
  return request({
    url: `/api/standard/OData/PhysicianMeetingExJoins?$filter=physician_id eq '${physician_id}' &$count=true`,
    method: 'get',
  });
}
// 查询岗位分型KPI
export function getTerritoryTypingKpi(territory_id) {
  return request({
    url: `/api/standard/OData/vTerritoryTypingKpis?$filter=territory_id eq '${territory_id}'`,
    method: 'get',
  });
}
export function getUserPhysicianProduct(data) {
  return request({
    url: `/api/standard/OData/UserPhysicianProducts?$filter=user_id eq '${data.user_id}' and physician_id eq '${data.physician_id}' &$orderby=product_id asc`,
    method: 'get',
  });
}

export function getIsSales(user_id) {
  return request({
    url: `/api/standard/OData/userRoles?$filter=user_id eq '${user_id}'`, // and territory_name eq '${data.user_name}'
    method: 'get',
  });
}
export function getInstitutionCpa(institution_id) {
  return request({
    url: `/api/standard/OData/institutionCpaStats?$filter=institution_id eq '${institution_id}' &$orderby=year desc`, // and territory_name eq '${data.user_name}'
    method: 'get',
  });
}
// export function getS3ByKey(key) {
//   return request({
//     url: `api/AWS/AWSService/GetObject?keyName=${key}`,
//     method: 'get',
//     responseType: 'blod'
//   });
// }

export function getS3ByKey(key) {
  return axios({
    url: `${BASE_URL}/api/AWS/AWSService/GetObject?keyName=${key}`,
    method: 'get',
    responseType: 'arraybuffer',
  });
}
export function getVPhysicianTypings(physician_id) {
  return request({
    url: `/api/standard/OData/VPhysicianTypings?$filter=physician_id eq '${physician_id}' and product_id eq 'Brintellix_000001'`,
    method: 'get',
  });
}

// 获取NBA配置
export function getPhysicianLadderNbas(data) {
  let filter = `product_id eq '${data.product_id}'`;
  if (data.segment_index) {
    filter += ` and (segment_index eq '${data.segment_index}' or segment_index eq 'ALL')`;
  } else {
    filter += ` and (segment_index eq 'ALL')`;
  }
  if (data.sub_segment) {
    filter += ` and (sub_segment eq '${data.sub_segment}' or sub_segment eq 'ALL')`;
  } else {
    filter += ` and (sub_segment eq 'ALL')`;
  }
  if (data.ladder_index) {
    filter += ` and (ladder_index eq '${data.ladder_index}' or ladder_index eq 'ALL')`;
  } else {
    filter += ` and (ladder_index eq 'ALL')`;
  }
  return request({
    url: `/api/standard/OData/CnfPhysicianLadderNbas?$filter=${filter}`,
    method: 'get',
  });
}
// 获取医生当前NBA分型
export function getLatestPhysicianSegment(data) {
  return request({
    url: `/api/standard/OData/PhysicianSegments?$filter=physician_id eq '${data.physician_id}' and product_id eq '${data.product_id}' &$orderby=create_time desc&$top=1`,
    method: 'get',
  });
}
// 获取医生NBA分型
export function getPhysicianSegments(data) {
  return request({
    url: `/api/standard/OData/PhysicianSegments?$filter=physician_id eq '${data.physician_id}' and product_id eq '${data.product_id}' &$orderby=create_time desc`,
    method: 'get',
  });
}
// 存入医生分型问题答案
export function setPhysicianSegment(data) {
  return request({
    url: `/api/standard/OData/PhysicianSegments`,
    method: 'post',
    data: data,
  });
}
// 获取医生当前NBA阶梯
export function getLatestPhysicianLadder(data) {
  return request({
    url: `/api/standard/OData/PhysicianLadders?$filter=physician_id eq '${data.physician_id}' and product_id eq '${data.product_id}' &$orderby=create_time desc&$top=1`,
    method: 'get',
  });
}
// 获取医生NBA阶梯
export function getPhysicianLadders(data) {
  return request({
    url: `/api/standard/OData/PhysicianLadders?$filter=physician_id eq '${data.physician_id}' and product_id eq '${data.product_id}' &$orderby=create_time desc`,
    method: 'get',
  });
}
// 存入医生分型问题答案
export function setPhysicianLadder(data) {
  return request({
    url: `/api/standard/OData/PhysicianLadders`,
    method: 'post',
    data: data,
  });
}

// 查询拜访列表
export function fetchPhysicianMrCalls(filter, skip, top) {
  let url = `/api/standard/odata/PhysicianMrCalls?$expand=physician&$orderby=call_time desc&$skip=${skip}&$top=${top}`;

  url = attachOdataQueryString(url, [getOdataFilterStringFromObject(filter)]);
  return request({
    url,
    method: 'get',
  });
}

// 查询单个拜访/协防
export function fetchOnePhysicianMrCall(id) {
  return request({
    url: `/api/standard/odata/PhysicianMrCalls('${id}')?$expand=physician,physician_mr_call__physician_mr_call_diner_physicians,physician_mr_call__physician_mr_call_diner_users`,
    method: 'get',
  });
}

// 获取拜访餐费预算
export function getGetMRCallBudget(data) {
  return request({
    url: `/api/gateway/CRM/GetMRCallBudget?call_user_id=${data.call_user_id}&call_time=${data.call_time}`,
    method: 'get',
  });
}

// 创建/修改拜访记录
export const createMrCall = (data) => {
  let url = '/api/standard/odata/PhysicianMrCalls';
  const id = data?.physician_mr_call_id;
  if (id) {
    url += `('${id}')`;
    delete data['@odata.context'];
    data = Object.fromEntries(
      Object.entries(data).filter(([_, value]) => value !== null),
    );
  }

  return request({
    url,
    method: id ? 'patch' : 'post',
    data: data,
  });
};
// 删除拜访记录
export const deleteMrCall = (id) => {
  const url = `/api/standard/odata/PhysicianMrCalls('${id}')`;

  return request({
    url,
    method: 'delete',
  });
};

// 创建拜访执行记录
export const createNbaExecution = (data) => {
  let url = '/api/standard/odata/NbaExecutions';
  const id = data?.nba_execution_id;
  if (id) {
    url += `('${id}')`;
    delete data['@odata.context'];
    data = Object.fromEntries(
      Object.entries(data).filter(([_, value]) => value !== null),
    );
  }

  return request({
    url,
    method: id ? 'patch' : 'post',
    data: data,
  });
};

// 创建企业微信消息
export const createMessage = (data) => {
  let url = '/api/standard/odata/Messages';
  const id = data?.message_id;
  if (id) {
    url += `('${id}')`;
    delete data['@odata.context'];
    data = Object.fromEntries(
      Object.entries(data).filter(([_, value]) => value !== null),
    );
  }

  return request({
    url,
    method: id ? 'patch' : 'post',
    data: data,
  });
};

// 寻找上级主管，结果在parent_user_id
export function findUser(data) {
  return request({
    url: `/api/standard/OData/Users?$filter=user_id eq '${data.user_id}'`,
    method: 'get',
  });
}
// 查询客户关注
export function getUserFavoritePhysician(user_id, physician_id) {
  return request({
    url: `/api/standard/OData/UserFavoritePhysicians?$filter=physician_id eq '${physician_id}' and user_id eq '${user_id}'
    `,
    method: 'get',
  });
}
// 创建/修改客户关注
export function updateUserFavoritePhysician(data) {
  let url = '/api/standard/odata/UserFavoritePhysicians';
  const id = data?.user_favorite_physician_id;
  if (id) {
    url += `('${id}')`;
    delete data['@odata.context'];
    data = Object.fromEntries(
      Object.entries(data).filter(([_, value]) => value !== null),
    );
  }

  return request({
    url,
    method: id ? 'patch' : 'post',
    data: data,
  });
}

// 查询用户反馈
export function getUserFeedback(user_id, physician_id) {
  return request({
    url: `/api/standard/OData/UserFeedbacks?$filter=related_physician_id eq '${physician_id}' and user_id eq '${user_id}'
    `,
    method: 'get',
  });
}
// 创建/修改用户反馈
export function createUserFeedback(data) {
  let url = '/api/standard/odata/UserFeedbacks';
  const id = data?.user_feedback_id;
  if (id) {
    url += `('${id}')`;
    delete data['@odata.context'];
    data = Object.fromEntries(
      Object.entries(data).filter(([_, value]) => value !== null),
    );
  }

  return request({
    url,
    method: id ? 'patch' : 'post',
    data: data,
  });
}

// 创建CRM拜访
export function createCRMCall(data) {
  return request({
    url: `/api/gateway/CRM/MRCall`,
    method: 'post',
    data: data,
  });
}
// 修改CRM拜访
export function updateCRMCall(data) {
  return request({
    url: `/api/gateway/CRM/MRCall`,
    method: 'patch',
    data: data,
  });
}
// 删除CRM拜访
export function deleteCRMCall(data) {
  return request({
    url: `/api/gateway/CRM/MRCall`,
    method: 'delete',
    data: data,
  });
}
